import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import SEO from './Seo';
import Article, { ArticleContainer } from './Article';

import { ArticleInterface } from '../shared/Interfaces';

interface Props {
    data: {
        allContentfulVest: {
            nodes: ArticleInterface[];
        }
    }
    pageContext: {
        title: string;
    }
}

const Category: React.FC<Props> = props => {

    const data = props.data.allContentfulVest.nodes;

    const [ showPosts, setShowPosts ] = useState<number>(8);
    const checkShowButton = () => data.length > showPosts;

    const [ showButton, setShowButton ] = useState<boolean>(checkShowButton());

    useEffect(() => {
        setShowButton(checkShowButton());
    }, [ showPosts ]);

    return (
        <Layout>
            <SEO title={props.pageContext.title} />
            <div style={{ textAlign: 'center'}}><h1 className="category__title">{props.pageContext.title}</h1></div>
            {data.length > 0 ? (
                <>
                <ArticleContainer type="double">
                    {data.slice(0, showPosts).map(post => <Article key={post.slug} {...post} type="double" /> )}
                </ArticleContainer>
                {showButton && (
                    <button className="btn" onClick={() => setShowPosts(showPosts + 8)}>
                        Učitaj još
                    </button>
                )}
                </>
            ) : <h1 className="category__empty">Nema vesti za ovu kategoriju.</h1> }
        </Layout>
    );
};

export const query = graphql`
query SingleCategoryQuery($title: [String]) {
    allContentfulVest(
        filter: {kategorija: {in: $title}}
        sort: {fields: createdAt, order: DESC}
        ) {
      nodes {
        naslov
        kategorija
        slug
        updatedAt
        slika {
          gatsbyImageData(
                aspectRatio: 1.5 
                width: 545
                resizingBehavior: THUMB
            )
        }
      }
    }
  }
`;

export default Category;